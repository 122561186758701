import { ResetCSS } from "common/assets/css/style";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { portfolioTheme } from "common/theme/portfolio";
import BannerSection from "containers/Portfolio/Banner";
import Footer from "containers/Portfolio/Footer";
import Navbar from "containers/Portfolio/Navbar";
import {
  ContentWrapper,
  GlobalStyle,
} from "containers/Portfolio/portfolio.style";
import PortfolioShowcase from "containers/Portfolio/PortfolioShowcase";
import HobbiesSection from "containers/Portfolio/Hobbies";
import SkillSection from "containers/Portfolio/Skill";
import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Harrison Hur | Hi there!</title>
          <meta name="description" content="Welcome to my personal website!" />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <PortfolioShowcase />
          <SkillSection />
          <HobbiesSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Portfolio;
