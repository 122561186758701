import React from "react";
import { SocialProfileWrapper, SocialProfileItem } from "./socialProfile.style";

const SocialProfile = ({ items, className, fontSize }) => {
  const addAllClasses = ["social_profiles"];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(" ")}>
      {items.map((item, index) => (
        <SocialProfileItem
          key={`social-item-${index}`}
          className="social_profile_item"
          fontSize={fontSize}
        >
          <a
            href={item.url || "#"}
            aria-label="social icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className={item.icon}></i>
          </a>
        </SocialProfileItem>
      ))}
    </SocialProfileWrapper>
  );
};

export default SocialProfile;
