import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import SocialProfile from "../SocialProfile";
import BannerWrapper from "./banner.style";

const BannerThemeWhite = "#fff";

const BannerSection = ({
  row,
  contentArea,
  imageArea,
  greetingStyle,
  nameStyle,
  designationStyle,
  aboutStyle,
  roleStyle,
  roleWrapper,
}) => {
  const data = useStaticQuery(graphql`
    query {
      person: file(
        relativePath: { eq: "image/portfolio/website_profile_pic.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 958
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      portfolioJson {
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...contentArea}>
            <Heading content="Hello, I’m" {...greetingStyle} />
            <Heading content="Harrison Hur" {...nameStyle} />
            <Heading
              content="Full-stack Software Engineer"
              {...designationStyle}
            />
            <Box {...roleWrapper}>
              <Heading
                content="@ Accenture NYC Liquid Studios"
                {...roleStyle}
              />
            </Box>
            <Box {...roleWrapper}>
              <SocialProfile items={data.portfolioJson.SOCIAL_PROFILES} />
            </Box>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleStyle: PropTypes.object,
  roleWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "stretch",
  },
  contentArea: {
    width: "100%",
    p: ["80px 0 0 0", "80px 0 80px 0", "80px 0 0 0", "0"],
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "left",
    flexDirection: "column",
  },
  imageArea: {
    width: ["100%", "100%", "50%", "60%"],
    flexBox: true,
    alignItems: "flex-end",
  },
  greetingStyle: {
    as: "h3",
    color: BannerThemeWhite,
    fontSize: ["18px", "18px", "18px", "20px", "30px"],
    fontWeight: "500",
    mb: "8px",
  },
  nameStyle: {
    as: "h2",
    color: BannerThemeWhite,
    fontSize: ["30px", "38px", "44px", "60px", "80px"],
    fontWeight: "800",
    mb: "6px",
  },
  designationStyle: {
    as: "h3",
    color: BannerThemeWhite,
    fontSize: ["14px", "18px", "18px", "20px", "30px"],
    fontWeight: "700",
    mb: ["15px", "22px", "22px", "26px", "26px"],
  },
  roleWrapper: {
    flexBox: true,
    mb: ["15px", "22px", "22px", "26px", "26px"],
  },
  roleStyle: {
    as: "h4",
    fontSize: ["12px", "18px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "#fff",
    mb: "0",
  },
  aboutStyle: {
    fontSize: ["15px", "15px", "15px", "16px", "16px"],
    fontWeight: "400",
    color: "#fff",
    lineHeight: "1.5",
    mb: "50px",
  },
};

export default BannerSection;
