import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
} from "./portfolioShowcase.style";
import CommonStyles from "../../../common/assets/common/common_styles";

const PortfolioShowcase = ({
  secTitleWrapper,
  secTitle,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PORTFOLIO_SHOWCASE {
          id
          title
          description
          link
          buildWith {
            content
          }
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  return (
    <Box {...CommonStyles.sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...CommonStyles.secTitleWrapper}>
          <Heading {...CommonStyles.mainSecTitle} content="Projects" />
          <Text
            {...CommonStyles.secDescription}
            content="Below are some of my most recent projects!"
          />
        </Box>

        <PortfolioShowcaseWrapper>
          {Data.portfolioJson.PORTFOLIO_SHOWCASE.map((portfolioItem, index) => (
            <div key={portfolioItem.id}>
              <Heading {...secTitle} content={portfolioItem.title} />
              <PortfolioShowcaseItem>
                <Box {...portfolioImage}>
                  <GatsbyImage
                    src={
                      (portfolioItem.image !== null) | undefined
                        ? portfolioItem.image.childImageSharp.gatsbyImageData
                        : {}
                    }
                    alt={`PortfolioImage-${index + 1}`}
                  />
                </Box>
                <Box {...portfolioDetails}>
                  <PortfolioLink>
                    <a
                      href={portfolioItem.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      VISIT PROJECT
                    </a>
                  </PortfolioLink>
                  <Text content={portfolioItem.description} {...detailsStyle} />
                  {portfolioItem.buildWith ? (
                    <BuiltWith>
                      {portfolioItem.buildWith.map((item, index) => (
                        <span key={`buildWith-item-${index}`}>
                          {item.content}
                        </span>
                      ))}
                    </BuiltWith>
                  ) : (
                    ""
                  )}
                </Box>
              </PortfolioShowcaseItem>{" "}
            </div>
          ))}
        </PortfolioShowcaseWrapper>
      </Container>
    </Box>
  );
};

PortfolioShowcase.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  secTitle: {
    fontSize: ["22px", "24px", "26px", "28px", "40px"],
    fontWeight: "600",
    color: "#302b4e",
    lineHeight: "1.34",
    mt: ["25px", "40px"],
    mb: ["15px", "18px", "18px", "20px", "20px"],
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ["30px 0 0 0", "40px 0 0 0", "0 0 0 30px", "0 50px", "0 50px"],
  },
  titleStyle: {
    fontSize: ["22px", "22px", "26px", "40px", "40px"],
    fontWeight: "600",
    color: "#302b4e",
    mb: "17px",
  },
  detailsStyle: {
    fontSize: ["15px", "15px", "15px", "16px", "20px"],
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
  },
};

export default PortfolioShowcase;
