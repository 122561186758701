import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Container from "common/components/UI/Container";
import SocialProfile from "../SocialProfile";

import { FooterWrapper } from "./footer.style";

const Footer = ({
  row,
  col,
  colRight,
  titleStyle,
  linkStyle,
  newsletterButton,
  copyrightStyle,
  contactItem,
  flexBox,
  contactTitle,
  contactInfo,
  noMargin,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...col}>
            <SocialProfile
              className="footer_social"
              items={Data.portfolioJson.SOCIAL_PROFILES}
            />
          </Box>

          <Box {...colRight}>
            <Text
              as="span"
              content="© 2022 All rights reserved."
              {...copyrightStyle}
            />
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colRight: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "space-between",
    ml: "-15px",
    mr: "-15px",
    mb: ["0", "50px", "50px", "50px", "50px"],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: "15px",
    pr: "15px",
    mb: ["40px", "0", "0", "0", "0", "0"],
  },
  colRight: {
    pl: "15px",
    textAlign: "right",
    mb: ["40px", "0", "0", "0", "0", "0"],
  },
  titleStyle: {
    fontSize: ["16px", "18px"],
    fontWeight: "600",
    mb: ["20px", "25px"],
  },
  linkStyle: {
    fontSize: ["22px", "26px", "26px", "30px"],
    color: "#3444f1",
    mb: 0,
  },
  newsletterButton: {
    type: "button",
    fontSize: "16px",
    pl: "20px",
    pr: "20px",
    colors: "primary",
    minHeight: "auto",
  },
  copyrightStyle: {
    fontSize: ["14px", "18px"],
    color: "#fff",
  },
  flexBox: {
    flexBox: true,
    justifyContent: "space-between",
    // flexWrap: 'wrap'
  },
  contactItem: {
    // width: 1 / 2
  },
  contactTitle: {
    fontSize: ["15x", "15px", "16px", "16px", "16px"],
    mb: "10px",
  },
  contactInfo: {
    fontSize: ["16x", "16px", "18px", "18px", "20px"],
    fontWeight: "500",
    mb: 0,
  },
  noMargin: {
    mb: "0",
  },
};

export default Footer;
