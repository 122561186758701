import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import { plus } from "react-icons-kit/feather/plus";

import { SkillItem, SkillDetails, SkillIcon, SkillAbout } from "./skill.style";
import CommonStyles from "../../../common/assets/common/common_styles";

const SkillSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  row,
  col,
  skillTitle,
  skillDescription,
  skillSuccessRate,
  successRateText,
  learningList,
  listItem,
  listText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        SKILLS {
          title
          icon {
            publicURL
          }
          listItems {
            content
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="skills_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...CommonStyles.secTitleWrapper}>
          <Heading {...CommonStyles.mainSecTitle} content="Skills" />
          <Text
            {...CommonStyles.secDescription}
            content="Here are some of my technical abilities -- I strive to grow my skillset each day."
          />
        </Box>

        <Box {...row}>
          {Data.portfolioJson.SKILLS.map((item, index) => (
            <Box {...col} key={`skill-item-${index}`}>
              <SkillItem>
                <SkillDetails>
                  <SkillIcon>
                    <Image
                      src={item.icon.publicURL}
                      alt={`skill-icon-${index + 1}`}
                    />
                  </SkillIcon>
                  <SkillAbout>
                    <Heading content={item.title} {...skillTitle} />
                    <Box {...learningList} key={`serviceList-${index}`}>
                      {item.listItems.map((subItem, index) => (
                        <Box {...listItem} key={`list-item-${index}`}>
                          <Icon icon={plus} size={12} />
                          <Text
                            as="span"
                            content={subItem.content}
                            {...listText}
                          />
                        </Box>
                      ))}
                    </Box>
                  </SkillAbout>
                </SkillDetails>
              </SkillItem>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

SkillSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
  learningList: PropTypes.object,
  listItem: PropTypes.object,
  listText: PropTypes.object,
};

SkillSection.defaultProps = {
  sectionWrapper: {
    pt: ["30px", "50px", "60px", "70px", "80px"],
    pb: ["30px", "20px", "20px", "30px", "30px"],
    bg: "#f9f9f9",
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "30px"],
    fontWeight: "700",
    color: "#302b4e",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "20px"],
    textAlign: "center",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mr: ["-15px", "-15px", "-15px", "-25px", "-25px"],
  },
  col: {
    width: [1, 1, 1 / 2],
    pl: ["15px", "15px", "15px", "25px", "25px"],
    pr: ["15px", "15px", "15px", "25px", "25px"],
    mb: ["30px", "30px", "30px", "50px", "50px"],
  },
  skillTitle: {
    fontSize: ["18px", "20px", "20px", "22px", "24px"],
    fontWeight: "600",
    color: "#302b4e",
    mb: "12px",
  },
  skillDescription: {
    fontSize: ["15px", "15px", "15px", "16px", "16px"],
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
  },
  skillSuccessRate: {
    fontSize: ["15px", "15px", "14px", "15px", "16px"],
    fontWeight: "400",
    color: "#302b4e",
    lineHeight: "1.5",
    mb: "0",
  },
  successRateText: {
    ml: ".3em",
    display: ["none", "none", "none", "none", "inline-block"],
  },
  learningList: {
    width: ["100%", "100%", "100%", "100%", "100%"],
    pl: ["0", "0", "10px", "15px", "20px"],
    pr: ["0", "30px", "0", "0", "0"],
    mb: ["40px", "40px", "60px", "80px", "90px"],
  },
  listItem: {
    flexBox: true,
    alignItems: "center",
    color: "#43414e",
    mb: "16px",
  },
  listText: {
    fontSize: ["14px", "16px", "16px", "16px", "18px"],
    fontWeight: "400",
    color: "#43414e",
    mb: "0",
    ml: "5px",
  },
};

export default SkillSection;
