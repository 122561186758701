const CommonStyles = {
  mainSecTitle: {
    fontSize: ["26px", "26px", "28px", "38px", "65px"],
    fontWeight: "500",
  },
  secDescription: {
    fontSize: ["16px", "20px"],
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
    width: "600px",
    maxWidth: "100%",
  },
  sectionWrapper: {
    pt: ["30px", "40px", "40px", "40px", "50px"],
    pb: ["30px", "40px", "40px", "40px", "50px"],
  },
  secTitleWrapper: {
    mb: ["30px", "40px", "50px", "60px", "70px"],
  },
};

export default CommonStyles;
