import styled from "styled-components";
import profile_picture from "../../../common/assets/image/portfolio/website_profile_pic.jpg";

const BannerWrapper = styled.section`
  position: relative;
  background-color: #081526;
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  align-items: flex-end;
  background-image: linear-gradient(
      to top,
      hsla(0, 0%, 2%, 0.64) 0%,
      hsla(0, 0%, 2%, 0.521) 10.3%,
      hsla(0, 0%, 2%, 0.419) 18%,
      hsla(0, 0%, 2%, 0.33) 23.5%,
      hsla(0, 0%, 2%, 0.255) 27.3%,
      hsla(0, 0%, 2%, 0.192) 29.9%,
      hsla(0, 0%, 2%, 0.141) 31.9%,
      hsla(0, 0%, 2%, 0.1) 33.7%,
      hsla(0, 0%, 2%, 0.067) 35.7%,
      hsla(0, 0%, 2%, 0.043) 38.6%,
      hsla(0, 0%, 2%, 0.025) 42.7%,
      hsla(0, 0%, 2%, 0.013) 48.7%,
      hsla(0, 0%, 2%, 0.006) 56.9%,
      hsla(0, 0%, 2%, 0.002) 67.8%,
      hsla(0, 0%, 2%, 0) 82%,
      hsla(0, 0%, 2%, 0) 100%
    ),
    url(${profile_picture});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    min-height: 100vh;
  }

  .image_area {
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    background-position: left 45% center;
  }

  @media (max-width: 480px) {
    background-position: left 30% center;
  }
`;

export default BannerWrapper;
